import { required, maxLength } from 'vuelidate/lib/validators';
import {  nameLength } from '@/_validations/validacionEspeciales';


export default () => {
  return {
    TpCargoNameEs: { required , nameLength, maxLength: maxLength(500) },
    TpCargoNameEn: {required, nameLength, maxLength: maxLength(500) },
    TpCargoDs: { required, maxLength: maxLength(500) },
    FgPackaging: { required },
    FgClassification: { required },
    TpCargoCode: { required, maxLength: maxLength(5) },
    Status: { required },
  };
};
